const ROUTES = {
  "/": {
    "name": "HomePage0e08f7d58a23455a8fdf356942dfa104",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPages063dd63d8b2a4a038e0e9731df7f76c7",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPage4c857ec321b3420bAd912974fb2a308d",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/about/leveling": {
    "name": "AboutLevelingPage7be55bd9907b4b31820900f7e5470255",
    "type": "HTML",
    "key": "about-leveling-page"
  },
  "/events/public": {
    "name": "PublicEventsPageA0e0fe94Fc6e4ad096a9B47d09582ee0",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/home1": {
    "name": "HomePage1Af1c952a0f0a4309B58a1b4ae2ba4541",
    "type": "HTML",
    "key": "home-page-1"
  },
  "/join": {
    "name": "JoinUsPageF4d7bb9976d14c32B581C6c7e44b32b8",
    "type": "HTML",
    "key": "join-us-page"
  },
  "/members/*": {
    "name": "MemberPages2ff9381c0e9e4ce384e14c62c79f6d5f",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePages6273a6796d3443f58468502c0d79db7a",
    "type": "HTML",
    "key": "message-pages"
  },
  "/resources": {
    "name": "ResourcesPage0e76d6d4Fb744f91Bc2101b3f3b4f4cc",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPages504aea07F5f448288e40Eb10b3408336",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;