import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "5f9e10b2-6311-41bd-8be6-67db83672959"
// title: ""
// type: :gf_preset
// key: "welcome-message"
// parent_id: "92085fc7-2215-4d44-8fd3-633053b6e2e0"
export function WelcomeMessage5f9e10b2631141bd8be667db83672959(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet parentTag={buildClassName("WelcomeMessage5f9e10b2631141bd8be667db83672959", parentTag)} contentSlug="home-welcome-message" {...props} />
  );
}

// id: "317f5769-e67d-47c5-9bc2-1e37e2883825"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "5f9e10b2-6311-41bd-8be6-67db83672959"
export const ContentSlug317f5769E67d47c59bc21e37e2883825 = "home-welcome-message";

// id: "e4229715-6959-4841-9cae-532da68cadd4"
// title: ""
// type: :gf_preset
// key: "book-stay-text"
// parent_id: "3612cc05-ad1b-455f-9de9-34919afa0418"
export function BookStayTextE4229715695948419cae532da68cadd4(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet parentTag={buildClassName("BookStayTextE4229715695948419cae532da68cadd4", parentTag)} contentSlug="home-book-stay" {...props} />
  );
}

// id: "e5944d02-a52a-45bc-b504-3b2f041b313d"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const TitleE5944d02A52a45bcB5043b2f041b313d = "Leveling";

// id: "6d4ca5ac-0222-4d7d-a680-1cf059cc78ed"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "efed6ad5-b613-4882-a26a-eddbb2fe04e3"
export const BackgroundImage6d4ca5ac02224d7dA6801cf059cc78ed = "https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg";

// id: "1278ba85-58ec-4818-aac3-f19b0beb1dc0"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "efed6ad5-b613-4882-a26a-eddbb2fe04e3"
export function Navigation1278ba8558ec4818Aac3F19b0beb1dc0(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation1278ba8558ec4818Aac3F19b0beb1dc0", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "45a3cb3b-b973-45bb-ad99-22f3dab687b5"
// title: ""
// type: :text
// key: "imageUrl"
// parent_id: "2586ef22-9f5f-4fe4-a7b3-24a7054e77e2"
export const ImageUrl45a3cb3bB97345bbAd9922f3dab687b5 = "https://mp1md-pub.s3-us-west-2.amazonaws.com/content/omEtAozQA9rCypTr_file.svg";

// id: "b9f31978-34d8-4f50-9b9c-98670a7ff46d"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "e4229715-6959-4841-9cae-532da68cadd4"
export const ContentSlugB9f3197834d84f509b9c98670a7ff46d = "home-book-stay";

// id: "5e9b89a6-b577-461f-9fdb-e58ff27f0241"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "f324590a-728e-4712-b2f6-0543aeedc157"
export const ContentSlug5e9b89a6B577461f9fdbE58ff27f0241 = "hero-description-heading";

// id: "0e76d6d4-fb74-4f91-bc21-01b3f3b4f4cc"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPage0e76d6d4Fb744f91Bc2101b3f3b4f4cc(props: any) {
  return (
    <SecondaryPage18586940D8674d41Bab74a6504586fc0 parentTag="ResourcesPage0e76d6d4Fb744f91Bc2101b3f3b4f4cc" header={<SecondaryMarketingHeader686cd6409234470aBb64A5fd4ced2cf2 parentTag="Header6c17edf8B4ec4711860eBa2254c585cd" title="Resources" />} {...props} />
  );
}

// id: "2224f80d-45ea-4dd6-8e91-24244b1d9872"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "f94eb81d-cc8a-44ac-854a-cb654f58a20d"
export const PostSlug2224f80d45ea4dd68e9124244b1d9872 = "home-page-offerings";

// id: "504aea07-f5f4-4828-8e40-eb10b3408336"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPages504aea07F5f448288e40Eb10b3408336(props: any) {
  return (
    <SecondaryPage18586940D8674d41Bab74a6504586fc0 parentTag="SignupPages504aea07F5f448288e40Eb10b3408336" header={<SecondaryMarketingHeader686cd6409234470aBb64A5fd4ced2cf2 parentTag="HeaderB5466e6e89a94b1895bfD051fbce2a28" title="Join Us" />} {...props} />
  );
}

// id: "21ac267f-9ee1-4c7a-a5b3-8a7e54afbbea"
// title: ""
// type: :reference
// key: "header"
// parent_id: "f4d7bb99-76d1-4c32-b581-c6c7e44b32b8"
export function Header21ac267f9ee14c7aA5b38a7e54afbbea(props: any) {
  return (
    <SecondaryMarketingHeader686cd6409234470aBb64A5fd4ced2cf2 parentTag="Header21ac267f9ee14c7aA5b38a7e54afbbea" title="Join Us" {...props} />
  );
}

// id: "aad8c787-4841-4f30-9bd1-ed4b3b066e9a"
// title: ""
// type: :reference
// key: "header"
// parent_id: "af1c952a-0f0a-4309-b58a-1b4ae2ba4541"
export function HeaderAad8c78748414f309bd1Ed4b3b066e9a(props: any) {
  return (
    <HomeHeaderC4d7847430f249cb8171E6c1a78421e5 parentTag="HeaderAad8c78748414f309bd1Ed4b3b066e9a" {...props} />
  );
}

// id: "6273a679-6d34-43f5-8468-502c0d79db7a"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePages6273a6796d3443f58468502c0d79db7a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePages6273a6796d3443f58468502c0d79db7a gf-page-layout`}>
      {props["header"] || <MembersAreaHeaderAdee4dbb7d5646c9910fB0c9ee29d322 parentTag="Header7c63619a489a4f2eA533Ebaf4cf1bf3c" {...props} />}
      {props.children}
    </div>
  );
}

// id: "307366cc-afee-4473-8efb-f254855042f5"
// title: ""
// type: :text
// key: "title"
// parent_id: "686cd640-9234-470a-bb64-a5fd4ced2cf2"
export const Title307366ccAfee44738efbF254855042f5 = "Secondary Marketing Title 1";

// id: "dd4c42d4-37b5-49c3-9460-fa92fdd54548"
// title: ""
// type: :reference
// key: "header"
// parent_id: "f41290b3-14ab-4631-9c79-ca19f99028c5"
export function HeaderDd4c42d437b549c39460Fa92fdd54548(props: any) {
  return (
    <MembersAreaHeaderAdee4dbb7d5646c9910fB0c9ee29d322 parentTag="HeaderDd4c42d437b549c39460Fa92fdd54548" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "780ea8cb-6ac4-46e2-9013-05983db4d3be"
// title: ""
// type: :text
// key: "title"
// parent_id: "1bedbca9-7534-442b-8540-35813b6c120f"
export const Title780ea8cb6ac446e2901305983db4d3be = "About Us";

// id: "063dd63d-8b2a-4a03-8e0e-9731df7f76c7"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPages063dd63d8b2a4a038e0e9731df7f76c7(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPages063dd63d8b2a4a038e0e9731df7f76c7`}>
      {props.children}
    </div>
  );
}

// id: "791247ee-67d6-4528-bfb6-d14f13699592"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "686cd640-9234-470a-bb64-a5fd4ced2cf2"
export function Hero791247ee67d64528Bfb6D14f13699592(props: any) {
  return (
    <SecondaryMarketingHero93936940D95341ba9d0173041fb950b2 parentTag="Hero791247ee67d64528Bfb6D14f13699592" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "7494ae41-ee8c-4291-a114-2e694a0f1e7b"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "091c6361-e3d9-4eb9-b408-5a3aa216fd75"
export const PostSlug7494ae41Ee8c4291A1142e694a0f1e7b = "home-page-posts2";

// id: "0ec8d55a-7e89-4c91-b125-402d5c88c736"
// title: "site-logo-name"
// type: :html
// key: "site-logo-name"
// parent_id: "380cec6c-c444-43a1-819c-e83be4ba3649"
export function SiteLogoName0ec8d55a7e894c91B125402d5c88c736(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/s2mZXXoqbYzP1qPv_file.svg" alt="wildfern grove logo" className={`${parentTag || ""} SiteLogoName0ec8d55a7e894c91B125402d5c88c736 site-name-logo`} />
  );
}

// id: "bf9e7a03-4a31-4fdd-91f1-28ac764ef149"
// title: ""
// type: :gf_preset
// key: "heading"
// parent_id: "796b8da7-fcab-48a6-ba5a-e69f437a5ba3"
export function HeadingBf9e7a034a314fdd91f128ac764ef149(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet parentTag={buildClassName("HeadingBf9e7a034a314fdd91f128ac764ef149", parentTag)} contentSlug="home-hero-heading" {...props} />
  );
}

// id: "25a656d6-b920-4657-8910-032c77dbdfc2"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function Label25a656d6B92046578910032c77dbdfc2(props: any) {
  return (
    <DefaultLogo2586ef229f5f4fe4A7b324a7054e77e2 parentTag="Label25a656d6B92046578910032c77dbdfc2" {...props} />
  );
}

// id: "a801be3f-6f1c-485c-8bac-34b897c6f1bd"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "af1c952a-0f0a-4309-b58a-1b4ae2ba4541"
export function FooterA801be3f6f1c485c8bac34b897c6f1bd(props: any) {
  return (
    <DefaultFooter380cec6cC44443a1819cE83be4ba3649 parentTag="FooterA801be3f6f1c485c8bac34b897c6f1bd" {...props} />
  );
}

// id: "d1428c7d-ea2e-4a10-b9a7-d7f8d02fce20"
// title: ""
// type: :reference
// key: "header"
// parent_id: "a0e0fe94-fc6e-4ad0-96a9-b47d09582ee0"
export function HeaderD1428c7dEa2e4a10B9a7D7f8d02fce20(props: any) {
  return (
    <SecondaryMarketingHeader686cd6409234470aBb64A5fd4ced2cf2 parentTag="HeaderD1428c7dEa2e4a10B9a7D7f8d02fce20" title="Public Events" {...props} />
  );
}

// id: "de4c8325-5538-4a9d-bf74-78a45b8063ed"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "730fe305-beb7-431c-bcda-65a673c1c014"
export const WidgetIdDe4c832555384a9dBf7478a45b8063ed = "4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d";

// id: "edf9e19d-3dc0-4fd1-b4af-3fdd9c45ded3"
// title: ""
// type: :text
// key: "title"
// parent_id: "d1428c7d-ea2e-4a10-b9a7-d7f8d02fce20"
export const TitleEdf9e19d3dc04fd1B4af3fdd9c45ded3 = "Public Events";

// id: "9b44de53-21fa-4be0-b89b-c24d7e0ee260"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeader9b44de5321fa4be0B89bC24d7e0ee260(props: any) {
  return (
    <ShortHeaderEfed6ad5B6134882A26aEddbb2fe04e3 parentTag="DefaultHeader9b44de5321fa4be0B89bC24d7e0ee260" title navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "adee4dbb-7d56-46c9-910f-b0c9ee29d322"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeaderAdee4dbb7d5646c9910fB0c9ee29d322(props: any) {
  return (
    <ShortHeaderEfed6ad5B6134882A26aEddbb2fe04e3 parentTag="MembersAreaHeaderAdee4dbb7d5646c9910fB0c9ee29d322" header-top={<DefaultHeaderTop3f0fe05dC0e2438cA8caC181594934a0 parentTag="HeaderTopD1088d0cC2684d0d9cf6466f3a8de12e" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "c36fc941-a07e-4d86-b940-33ed0fb8371e"
// title: ""
// type: :reference
// key: "marketingCards"
// parent_id: "28d5934c-8dde-4a7c-a9f5-202705f94d48"
export function MarketingCardsC36fc941A07e4d86B94033ed0fb8371e(props: any) {
  return (
    <MarketingCards091c6361E3d94eb9B4085a3aa216fd75 parentTag="MarketingCardsC36fc941A07e4d86B94033ed0fb8371e" postSlug="home-page-posts2" {...props} />
  );
}

// id: "9bfcbc18-1361-41ee-bec7-686ffed34435"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "380cec6c-c444-43a1-819c-e83be4ba3649"
export function Groupflow9bfcbc18136141eeBec7686ffed34435(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("Groupflow9bfcbc18136141eeBec7686ffed34435", parentTag)} {...props} />
  );
}

// id: "09cbfaa9-5a0b-46e3-94e9-f88328fcd135"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "c36fc941-a07e-4d86-b940-33ed0fb8371e"
export const PostSlug09cbfaa95a0b46e394e9F88328fcd135 = "home-page-posts2";

// id: "43e9b759-855e-486a-ae9e-6b96a6277275"
// title: ""
// type: :reference
// key: "label"
// parent_id: "88b68c32-034f-4b21-b4f8-e8133508699a"
export function Label43e9b759855e486aAe9e6b96a6277275(props: any) {
  return (
    <DefaultLogo2586ef229f5f4fe4A7b324a7054e77e2 parentTag="Label43e9b759855e486aAe9e6b96a6277275" {...props} />
  );
}

// id: "f1ff0d1e-7492-4e89-9f5e-91f4beb8a8cb"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuoteF1ff0d1e74924e899f5e91f4beb8a8cb(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuoteF1ff0d1e74924e899f5e91f4beb8a8cb`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "3a272821-e8a6-4ba2-8e5e-0b2a10604ba2"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "686cd640-9234-470a-bb64-a5fd4ced2cf2"
export function HeaderTop3a272821E8a64ba28e5e0b2a10604ba2(props: any) {
  return (
    <DefaultHeaderTop3f0fe05dC0e2438cA8caC181594934a0 parentTag="HeaderTop3a272821E8a64ba28e5e0b2a10604ba2" {...props} />
  );
}

// id: "3951e198-7beb-406e-ad7f-075b26037cd3"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "2ff9381c-0e9e-4ce3-84e1-4c62c79f6d5f"
export function SecondaryNav3951e1987beb406eAd7f075b26037cd3(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNav3951e1987beb406eAd7f075b26037cd3", parentTag)} {...props} />
  );
}

// id: "7dd46aa0-2edb-450b-b452-73b6d94e9074"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "1278ba85-58ec-4818-aac3-f19b0beb1dc0"
export const NavMenuSlug7dd46aa02edb450bB45273b6d94e9074 = "members-primary-nav";

// id: "b5c59ab7-1801-458d-a038-5214b7c2366a"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "dd4c42d4-37b5-49c3-9460-fa92fdd54548"
export const NavMenuSlugB5c59ab71801458dA0385214b7c2366a = "members-primary-nav";

// id: "a0e0fe94-fc6e-4ad0-96a9-b47d09582ee0"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPageA0e0fe94Fc6e4ad096a9B47d09582ee0(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPageA0e0fe94Fc6e4ad096a9B47d09582ee0 gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeader686cd6409234470aBb64A5fd4ced2cf2 parentTag="HeaderD1428c7dEa2e4a10B9a7D7f8d02fce20" title="Public Events" {...props} />}
      <main>
        {props.children}
      </main>
      {props["footer"] || <DefaultFooter380cec6cC44443a1819cE83be4ba3649 parentTag="FooterFbd677498a264159B784B7b7e1d907ab" {...props} />}
    </div>
  );
}

// id: "ff358daf-8f96-4ffe-9b77-5edb5fdb83d1"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHeroFf358daf8f964ffe9b775edb5fdb83d1(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <section className={`${parentTag || ""} HomePageHeroFf358daf8f964ffe9b775edb5fdb83d1 `}>
      <div className="hero-content">
        {props["heading"] || <ContentSnippet parentTag={buildClassName("Heading55997bef896b4f0fBb083ddd0189445b", parentTag)} contentSlug="home-hero-heading" {...props} />}
        <div className="hero-button-container">
          <a href="#welcome-main-section">
            <button className="hero-button">
              <span className="svg-container-hero">
                <svg className="button-svg" data-bbox="19.999 58 160.001 84" viewBox="0 0 200 200" height="28" width="28" xmlns="http://www.w3.org/2000/svg" data-type="shape">
                  <g transform="rotate(180 100 100)">
                    <path d="M172.5 142a7.485 7.485 0 0 1-5.185-2.073L100 75.808l-67.315 64.12c-2.998 2.846-7.74 2.744-10.606-.234a7.454 7.454 0 0 1 .235-10.565l72.5-69.057a7.524 7.524 0 0 1 10.371 0l72.5 69.057a7.455 7.455 0 0 1 .235 10.565A7.503 7.503 0 0 1 172.5 142z" fill="white" />
                  </g>
                </svg>
              </span>
            </button>
          </a>
        </div>
      </div>
    </section>
  );
}

// id: "c0a200f9-f029-4aa5-a0ac-55bd5407430b"
// title: ""
// type: :text
// key: "alt"
// parent_id: "2586ef22-9f5f-4fe4-a7b3-24a7054e77e2"
export const AltC0a200f9F0294aa5A0ac55bd5407430b = "logo";

// id: "566e273c-46da-4b09-bb6e-044392ea24a6"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "4c857ec3-21b3-420b-ad91-2974fb2a308d"
export function Footer566e273c46da4b09Bb6e044392ea24a6(props: any) {
  return (
    <DefaultFooter380cec6cC44443a1819cE83be4ba3649 parentTag="Footer566e273c46da4b09Bb6e044392ea24a6" {...props} />
  );
}

// id: "45e5d961-5f6f-42c9-985e-6057c26f8aef"
// title: ""
// type: :text
// key: "url"
// parent_id: "a93127c8-e1be-43fd-a274-969971299572"
export const Url45e5d9615f6f42c9985e6057c26f8aef = "/";

// id: "fd6424bb-ebfd-4002-b8b9-fdd9e717ddad"
// title: ""
// type: :html
// key: "label"
// parent_id: "a93127c8-e1be-43fd-a274-969971299572"
export function LabelFd6424bbEbfd4002B8b9Fdd9e717ddad(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/omEtAozQA9rCypTr_file.svg" className={`${parentTag || ""} LabelFd6424bbEbfd4002B8b9Fdd9e717ddad logo`} />
  );
}

// id: "f4d7bb99-76d1-4c32-b581-c6c7e44b32b8"
// title: "Join Us Page"
// type: :html
// key: "join-us-page"
// parent_id: nil
export function JoinUsPageF4d7bb9976d14c32B581C6c7e44b32b8(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} JoinUsPageF4d7bb9976d14c32B581C6c7e44b32b8 gf-page-layout`} header={<SecondaryMarketingHeader686cd6409234470aBb64A5fd4ced2cf2 parentTag="Header21ac267f9ee14c7aA5b38a7e54afbbea" title="Join Us" />}>
      {props["header"] || <SecondaryMarketingHeader686cd6409234470aBb64A5fd4ced2cf2 parentTag="Header8a316de1E97f420fA698D5459e6cac3d" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter380cec6cC44443a1819cE83be4ba3649 parentTag="FooterF5fa5def8ca842159afbBc4c3b60a1e9" {...props} />}
    </div>
  );
}

// id: "936d9189-1811-4a20-8d55-9b8fee0a3169"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "c4d78474-30f2-49cb-8171-e6c1a78421e5"
export function HeaderTop936d918918114a208d559b8fee0a3169(props: any) {
  return (
    <DefaultHeaderTop3f0fe05dC0e2438cA8caC181594934a0 parentTag="HeaderTop936d918918114a208d559b8fee0a3169" widgetId="6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5" {...props} />
  );
}

// id: "a93127c8-e1be-43fd-a274-969971299572"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "3f0fe05d-c0e2-438c-a8ca-c181594934a0"
export function LinkedLogoA93127c8E1be43fdA274969971299572(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogoA93127c8E1be43fdA274969971299572", parentTag)} url="/" label={<LabelFd6424bbEbfd4002B8b9Fdd9e717ddad />} className="linked-logo" {...props} />
  );
}

// id: "01be1443-8ba1-46b1-8b0c-68c443eea87e"
// title: ""
// type: :text
// key: "class"
// parent_id: "9f3dc81f-a2a8-4cde-83c3-36bd8fe3e18a"
export const Class01be14438ba146b18b0c68c443eea87e = "navigation";

// id: "f41290b3-14ab-4631-9c79-ca19f99028c5"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefaultF41290b314ab46319c79Ca19f99028c5(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefaultF41290b314ab46319c79Ca19f99028c5 page`}>
      {props["header"] || <MembersAreaHeaderAdee4dbb7d5646c9910fB0c9ee29d322 parentTag="HeaderDd4c42d437b549c39460Fa92fdd54548" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooter380cec6cC44443a1819cE83be4ba3649 parentTag="FooterC368e0a2613845c586d90407acaae605" {...props} />}
    </div>
  );
}

// id: "eb1cce77-e3b5-46df-8dd8-15c032359c19"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "2ff9381c-0e9e-4ce3-84e1-4c62c79f6d5f"
export function FooterEb1cce77E3b546df8dd815c032359c19(props: any) {
  return (
    <DefaultFooter380cec6cC44443a1819cE83be4ba3649 parentTag="FooterEb1cce77E3b546df8dd815c032359c19" {...props} />
  );
}

// id: "2ac9b251-1f50-4a54-98c3-004e78cfd50c"
// title: ""
// type: :reference
// key: "header"
// parent_id: "2ff9381c-0e9e-4ce3-84e1-4c62c79f6d5f"
export function Header2ac9b2511f504a5498c3004e78cfd50c(props: any) {
  return (
    <MembersAreaHeaderAdee4dbb7d5646c9910fB0c9ee29d322 parentTag="Header2ac9b2511f504a5498c3004e78cfd50c" {...props} />
  );
}

// id: "619c0a57-9afc-45fc-abd2-bfce07a535fa"
// title: ""
// type: :text
// key: "title"
// parent_id: "9b44de53-21fa-4be0-b89b-c24d7e0ee260"
export const Title619c0a579afc45fcAbd2Bfce07a535fa = null;

// id: "e6659071-c446-4387-9ecd-804fe62938b0"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "9f3dc81f-a2a8-4cde-83c3-36bd8fe3e18a"
export const NavMenuSlugE6659071C44643879ecd804fe62938b0 = "marketing-primary-nav";

// id: "9280fb55-4afb-4d35-befc-66d3dc76eaaf"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const Href9280fb554afb4d35Befc66d3dc76eaaf = "/";

// id: "f673dd3b-cdfd-44a2-b99f-7b3220f142d4"
// title: ""
// type: :reference
// key: "header"
// parent_id: "18586940-d867-4d41-bab7-4a6504586fc0"
export function HeaderF673dd3bCdfd44a2B99f7b3220f142d4(props: any) {
  return (
    <DefaultHeaderTop3f0fe05dC0e2438cA8caC181594934a0 parentTag="HeaderF673dd3bCdfd44a2B99f7b3220f142d4" title="Secondary Page" {...props} />
  );
}

// id: "730fe305-beb7-431c-bcda-65a673c1c014"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "c4d78474-30f2-49cb-8171-e6c1a78421e5"
export function Hero730fe305Beb7431cBcda65a673c1c014(props: any) {
  return (
    <HomePageHeroFf358daf8f964ffe9b775edb5fdb83d1 parentTag="Hero730fe305Beb7431cBcda65a673c1c014" widgetId="4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d" {...props} />
  );
}

// id: "d1088d0c-c268-4d0d-9cf6-466f3a8de12e"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "adee4dbb-7d56-46c9-910f-b0c9ee29d322"
export function HeaderTopD1088d0cC2684d0d9cf6466f3a8de12e(props: any) {
  return (
    <DefaultHeaderTop3f0fe05dC0e2438cA8caC181594934a0 parentTag="HeaderTopD1088d0cC2684d0d9cf6466f3a8de12e" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "7b5f62e9-def0-49e9-accd-700643317c7a"
// title: ""
// type: :text
// key: "title"
// parent_id: "b5466e6e-89a9-4b18-95bf-d051fbce2a28"
export const Title7b5f62e9Def049e9Accd700643317c7a = "Join Us";

// id: "b73e778b-b1bf-487b-857d-567fe79f4acb"
// title: ""
// type: :gf_preset
// key: "description-para"
// parent_id: "796b8da7-fcab-48a6-ba5a-e69f437a5ba3"
export function DescriptionParaB73e778bB1bf487b857d567fe79f4acb(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet parentTag={buildClassName("DescriptionParaB73e778bB1bf487b857d567fe79f4acb", parentTag)} contentSlug="hero-description-para" {...props} />
  );
}

// id: "54b24d2d-feb4-425c-9477-0a6e49f44ac1"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function Footer54b24d2dFeb4425c94770a6e49f44ac1(props: any) {
  return (
    <DefaultFooter380cec6cC44443a1819cE83be4ba3649 parentTag="Footer54b24d2dFeb4425c94770a6e49f44ac1" {...props} />
  );
}

// id: "7193e0a5-b85c-4317-988e-5ad42c82fd65"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "091c6361-e3d9-4eb9-b408-5a3aa216fd75"
export const ButtonClass7193e0a5B85c4317988e5ad42c82fd65 = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "2c5d6419-7918-4f04-b365-a2f3c7e9adc1"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "380cec6c-c444-43a1-819c-e83be4ba3649"
export function Copyright2c5d641979184f04B365A2f3c7e9adc1(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("Copyright2c5d641979184f04B365A2f3c7e9adc1", parentTag)} {...props} />
  );
}

// id: "50eaa5e7-13ae-450a-973f-fc65280e9229"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "c4d78474-30f2-49cb-8171-e6c1a78421e5"
export const BackgroundImage50eaa5e713ae450a973fFc65280e9229 = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/Moving_Forest_1050_700.webp";

// id: "cc86dc70-ad8e-4086-ab28-09db7625c52c"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "d1088d0c-c268-4d0d-9cf6-466f3a8de12e"
export const NavMenuSlugCc86dc70Ad8e4086Ab2809db7625c52c = "members-primary-nav";

// id: "18586940-d867-4d41-bab7-4a6504586fc0"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPage18586940D8674d41Bab74a6504586fc0(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPage18586940D8674d41Bab74a6504586fc0 gf-page-layout`}>
      {props["header"] || <DefaultHeaderTop3f0fe05dC0e2438cA8caC181594934a0 parentTag="HeaderF673dd3bCdfd44a2B99f7b3220f142d4" title="Secondary Page" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter380cec6cC44443a1819cE83be4ba3649 parentTag="Footer2640f31a405246b888d13739292eafb1" {...props} />}
    </div>
  );
}

// id: "ecfc6683-bbab-4b32-89bb-dac3194851c9"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "ff358daf-8f96-4ffe-9b77-5edb5fdb83d1"
export function ButtonEcfc6683Bbab4b3289bbDac3194851c9(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("ButtonEcfc6683Bbab4b3289bbDac3194851c9", parentTag)} href="/join" label="Join Us" {...props} />
  );
}

// id: "2640f31a-4052-46b8-88d1-3739292eafb1"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "18586940-d867-4d41-bab7-4a6504586fc0"
export function Footer2640f31a405246b888d13739292eafb1(props: any) {
  return (
    <DefaultFooter380cec6cC44443a1819cE83be4ba3649 parentTag="Footer2640f31a405246b888d13739292eafb1" {...props} />
  );
}

// id: "8fd82fed-7714-49db-972c-04f710d22590"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "af1c952a-0f0a-4309-b58a-1b4ae2ba4541"
export function WelcomeBack8fd82fed771449db972c04f710d22590(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack8fd82fed771449db972c04f710d22590", parentTag)} {...props} />
  );
}

// id: "636326cc-96ee-40e7-9933-8cda1cdc5538"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "41b87e7c-e5e7-4984-a6ed-ac14a7f40a1f"
export const NavMenuSlug636326cc96ee40e799338cda1cdc5538 = "footer-nav";

// id: "7be55bd9-907b-4b31-8209-00f7e5470255"
// title: "Levels 1-4 Explained"
// type: :html
// key: "about-leveling-page"
// parent_id: nil
export function AboutLevelingPage7be55bd9907b4b31820900f7e5470255(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutLevelingPage7be55bd9907b4b31820900f7e5470255 gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeader686cd6409234470aBb64A5fd4ced2cf2 parentTag="Header9d8124778d5f425cB3c9E50c4e432972" title="Leveling" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter380cec6cC44443a1819cE83be4ba3649 parentTag="FooterEdf4fb9660864e158d184a00a8cf16aa" {...props} />}
    </div>
  );
}

// id: "fbd67749-8a26-4159-b784-b7b7e1d907ab"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "a0e0fe94-fc6e-4ad0-96a9-b47d09582ee0"
export function FooterFbd677498a264159B784B7b7e1d907ab(props: any) {
  return (
    <DefaultFooter380cec6cC44443a1819cE83be4ba3649 parentTag="FooterFbd677498a264159B784B7b7e1d907ab" {...props} />
  );
}

// id: "6c17edf8-b4ec-4711-860e-ba2254c585cd"
// title: ""
// type: :reference
// key: "header"
// parent_id: "0e76d6d4-fb74-4f91-bc21-01b3f3b4f4cc"
export function Header6c17edf8B4ec4711860eBa2254c585cd(props: any) {
  return (
    <SecondaryMarketingHeader686cd6409234470aBb64A5fd4ced2cf2 parentTag="Header6c17edf8B4ec4711860eBa2254c585cd" title="Resources" {...props} />
  );
}

// id: "406240bd-2e8b-4be3-b86d-16092ef9f2f2"
// title: ""
// type: :text
// key: "title"
// parent_id: "9d812477-8d5f-425c-b3c9-e50c4e432972"
export const Title406240bd2e8b4be3B86d16092ef9f2f2 = "Leveling";

// id: "f4066ea1-2c8b-42ad-b984-6e703b728376"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function HeaderF4066ea12c8b42adB9846e703b728376(props: any) {
  return (
    <MembersAreaHeaderAdee4dbb7d5646c9910fB0c9ee29d322 parentTag="HeaderF4066ea12c8b42adB9846e703b728376" {...props} />
  );
}

// id: "41b87e7c-e5e7-4984-a6ed-ac14a7f40a1f"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "380cec6c-c444-43a1-819c-e83be4ba3649"
export function Links41b87e7cE5e74984A6edAc14a7f40a1f(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("Links41b87e7cE5e74984A6edAc14a7f40a1f", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "7c63619a-489a-4f2e-a533-ebaf4cf1bf3c"
// title: ""
// type: :reference
// key: "header"
// parent_id: "6273a679-6d34-43f5-8468-502c0d79db7a"
export function Header7c63619a489a4f2eA533Ebaf4cf1bf3c(props: any) {
  return (
    <MembersAreaHeaderAdee4dbb7d5646c9910fB0c9ee29d322 parentTag="Header7c63619a489a4f2eA533Ebaf4cf1bf3c" {...props} />
  );
}

// id: "9f3dc81f-a2a8-4cde-83c3-36bd8fe3e18a"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "3f0fe05d-c0e2-438c-a8ca-c181594934a0"
export function Navigation9f3dc81fA2a84cde83c336bd8fe3e18a(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation9f3dc81fA2a84cde83c336bd8fe3e18a", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "5a033c34-06c5-481b-ad58-008b948475cc"
// title: ""
// type: :text
// key: "class"
// parent_id: "a93127c8-e1be-43fd-a274-969971299572"
export const Class5a033c3406c5481bAd58008b948475cc = "linked-logo";

// id: "f324590a-728e-4712-b2f6-0543aeedc157"
// title: ""
// type: :gf_preset
// key: "description-heading"
// parent_id: "796b8da7-fcab-48a6-ba5a-e69f437a5ba3"
export function DescriptionHeadingF324590a728e4712B2f60543aeedc157(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet parentTag={buildClassName("DescriptionHeadingF324590a728e4712B2f60543aeedc157", parentTag)} contentSlug="hero-description-heading" {...props} />
  );
}

// id: "2ff9381c-0e9e-4ce3-84e1-4c62c79f6d5f"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPages2ff9381c0e9e4ce384e14c62c79f6d5f(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPages2ff9381c0e9e4ce384e14c62c79f6d5f gf-page-layout`}>
      {props["header"] || <MembersAreaHeaderAdee4dbb7d5646c9910fB0c9ee29d322 parentTag="Header2ac9b2511f504a5498c3004e78cfd50c" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNav3951e1987beb406eAd7f075b26037cd3", parentTag)} {...props} />}
      <main className="MainContent">
        {props.children}
      </main>
      {props["footer"] || <DefaultFooter380cec6cC44443a1819cE83be4ba3649 parentTag="FooterEb1cce77E3b546df8dd815c032359c19" {...props} />}
    </div>
  );
}

// id: "c368e0a2-6138-45c5-86d9-0407acaae605"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "f41290b3-14ab-4631-9c79-ca19f99028c5"
export function FooterC368e0a2613845c586d90407acaae605(props: any) {
  return (
    <DefaultFooter380cec6cC44443a1819cE83be4ba3649 parentTag="FooterC368e0a2613845c586d90407acaae605" {...props} />
  );
}

// id: "88b68c32-034f-4b21-b4f8-e8133508699a"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "efed6ad5-b613-4882-a26a-eddbb2fe04e3"
export function LinkedLogo88b68c32034f4b21B4f8E8133508699a(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo88b68c32034f4b21B4f8E8133508699a", parentTag)} label={<DefaultLogo2586ef229f5f4fe4A7b324a7054e77e2 parentTag="Label43e9b759855e486aAe9e6b96a6277275" alt="logo" />} {...props} />
  );
}

// id: "14ae2031-994c-4fa8-ba87-001bda8c8b94"
// title: ""
// type: :html
// key: "logo"
// parent_id: "380cec6c-c444-43a1-819c-e83be4ba3649"
export function Logo14ae2031994c4fa8Ba87001bda8c8b94(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide-white-with-font.svg" alt="Demo 1 logo" className={`${parentTag || ""} Logo14ae2031994c4fa8Ba87001bda8c8b94 logo`} />
  );
}

// id: "35b62165-6f1a-451d-987e-da519eaa2f3b"
// title: ""
// type: :text
// key: "title"
// parent_id: "6c17edf8-b4ec-4711-860e-ba2254c585cd"
export const Title35b621656f1a451d987eDa519eaa2f3b = "Resources";

// id: "2586ef22-9f5f-4fe4-a7b3-24a7054e77e2"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogo2586ef229f5f4fe4A7b324a7054e77e2(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/omEtAozQA9rCypTr_file.svg" className={`${parentTag || ""} DefaultLogo2586ef229f5f4fe4A7b324a7054e77e2 logo`} alt="logo" />
  );
}

// id: "ed6aad53-4178-4879-8bc7-7d7c226247c8"
// title: ""
// type: :reference
// key: "body"
// parent_id: "af1c952a-0f0a-4309-b58a-1b4ae2ba4541"
export function BodyEd6aad53417848798bc77d7c226247c8(props: any) {
  return (
    <MarketingBody28d5934c8dde4a7cA9f5202705f94d48 parentTag="BodyEd6aad53417848798bc77d7c226247c8" {...props} />
  );
}

// id: "61f8cbd7-a032-45eb-8358-d00f71acd838"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "af1c952a-0f0a-4309-b58a-1b4ae2ba4541"
export function Quote61f8cbd7A03245eb8358D00f71acd838(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote parentTag={buildClassName("Quote61f8cbd7A03245eb8358D00f71acd838", parentTag)} contentSlug="home-page-quote" {...props} />
  );
}

// id: "823f9d63-a04d-4ee5-bc56-cdad7cb809aa"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "936d9189-1811-4a20-8d55-9b8fee0a3169"
export const WidgetId823f9d63A04d4ee5Bc56Cdad7cb809aa = "6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5";

// id: "796b8da7-fcab-48a6-ba5a-e69f437a5ba3"
// title: "hero-home-2"
// type: :html
// key: "hero-home-2"
// parent_id: "c4d78474-30f2-49cb-8171-e6c1a78421e5"
export function HeroHome2796b8da7Fcab48a6Ba5aE69f437a5ba3(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <section className={`${parentTag || ""} HeroHome2796b8da7Fcab48a6Ba5aE69f437a5ba3 `}>
      <div className="hero-content">
        <div className="homepage-forest-backdrop">
          <div className="homepage-sanctuary-title">
            <div className="hero-title-innerContainer">
              {props["heading"] || <ContentSnippet parentTag={buildClassName("HeadingBf9e7a034a314fdd91f128ac764ef149", parentTag)} contentSlug="home-hero-heading" {...props} />}
            </div>
            <div className="homepage-action-buttons">
              <button className="homepage-button homepage-reserve-button">
                Reserve Your Yurt
              </button>
              <button className="homepage-button homepage-host-button">
                Host Your Private Event
              </button>
            </div>
          </div>
          <div className="homepage-main-content">
            <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/y560e3L1XPuas_r-_file.svg" alt="Yurt in Forest" className="homepage-yurt-image" />
          </div>
        </div>
        <div className="hero-banner-description-container">
          <div className="hero-banner-description-Innercontainer">
            <div className="description-heading-container">
              <h1 className="description-heading">
                {props["description-heading"] || <ContentSnippet parentTag={buildClassName("DescriptionHeadingF324590a728e4712B2f60543aeedc157", parentTag)} contentSlug="hero-description-heading" {...props} />}
              </h1>
            </div>
            <div className="description-para-container">
              <p>
                {props["description-para"] || <ContentSnippet parentTag={buildClassName("DescriptionParaB73e778bB1bf487b857d567fe79f4acb", parentTag)} contentSlug="hero-description-para" {...props} />}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// id: "c4d78474-30f2-49cb-8171-e6c1a78421e5"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeaderC4d7847430f249cb8171E6c1a78421e5(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeaderC4d7847430f249cb8171E6c1a78421e5 site-header`}>
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop3f0fe05dC0e2438cA8caC181594934a0 parentTag="HeaderTop936d918918114a208d559b8fee0a3169" widgetId="6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5" {...props} />}
      </div>
      {props["hero"] || <HomePageHeroFf358daf8f964ffe9b775edb5fdb83d1 parentTag="Hero730fe305Beb7431cBcda65a673c1c014" widgetId="4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d" {...props} />}
    </div>
  );
}

// id: "9d812477-8d5f-425c-b3c9-e50c4e432972"
// title: ""
// type: :reference
// key: "header"
// parent_id: "7be55bd9-907b-4b31-8209-00f7e5470255"
export function Header9d8124778d5f425cB3c9E50c4e432972(props: any) {
  return (
    <SecondaryMarketingHeader686cd6409234470aBb64A5fd4ced2cf2 parentTag="Header9d8124778d5f425cB3c9E50c4e432972" title="Leveling" {...props} />
  );
}

// id: "46113fe5-0427-4f99-b4eb-b7029d9c41d6"
// title: ""
// type: :html
// key: "title"
// parent_id: "93936940-d953-41ba-9d01-73041fb950b2"
export function Title46113fe504274f99B4ebB7029d9c41d6(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <>

    </>
  );
}

// id: "f3c29add-9ac0-44b3-86d5-c398b4c1dfdb"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "61f8cbd7-a032-45eb-8358-d00f71acd838"
export const ContentSlugF3c29add9ac044b386d5C398b4c1dfdb = "home-page-quote";

// id: "6e97f00b-f541-4b07-a750-9a135c0a6251"
// title: ""
// type: :text
// key: "title"
// parent_id: "791247ee-67d6-4528-bfb6-d14f13699592"
export const Title6e97f00bF5414b07A7509a135c0a6251 = "Secondary Marketing Title 2";

// id: "4d342cbe-727b-4f3c-a23d-82bedc2b48c2"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "b73e778b-b1bf-487b-857d-567fe79f4acb"
export const ContentSlug4d342cbe727b4f3cA23d82bedc2b48c2 = "hero-description-para";

// id: "edf4fb96-6086-4e15-8d18-4a00a8cf16aa"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "7be55bd9-907b-4b31-8209-00f7e5470255"
export function FooterEdf4fb9660864e158d184a00a8cf16aa(props: any) {
  return (
    <DefaultFooter380cec6cC44443a1819cE83be4ba3649 parentTag="FooterEdf4fb9660864e158d184a00a8cf16aa" {...props} />
  );
}

// id: "a3d2e1c8-288d-4b09-88b2-fd2e6e532482"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "9b44de53-21fa-4be0-b89b-c24d7e0ee260"
export const NavMenuSlugA3d2e1c8288d4b0988b2Fd2e6e532482 = "marketing-primary-nav";

// id: "b5466e6e-89a9-4b18-95bf-d051fbce2a28"
// title: ""
// type: :reference
// key: "header"
// parent_id: "504aea07-f5f4-4828-8e40-eb10b3408336"
export function HeaderB5466e6e89a94b1895bfD051fbce2a28(props: any) {
  return (
    <SecondaryMarketingHeader686cd6409234470aBb64A5fd4ced2cf2 parentTag="HeaderB5466e6e89a94b1895bfD051fbce2a28" title="Join Us" {...props} />
  );
}

// id: "ae6674d7-bdfa-4d99-9713-7f37408216bf"
// title: ""
// type: :text
// key: "href"
// parent_id: "ecfc6683-bbab-4b32-89bb-dac3194851c9"
export const HrefAe6674d7Bdfa4d9997137f37408216bf = "/join";

// id: "e48a92a1-5ce9-45bf-9114-fb3a4dead763"
// title: ""
// type: :html
// key: "services"
// parent_id: "0e08f7d5-8a23-455a-8fdf-356942dfa104"
export function ServicesE48a92a15ce945bf9114Fb3a4dead763(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <section className={`${parentTag || ""} ServicesE48a92a15ce945bf9114Fb3a4dead763`}>
      <div className="bys-container">
        <div className="bys-section">
          <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/SwOg496DLJdgcrHn_regular.png" alt="Yurt Rentals" className="bys-image" />
          <div className="bys-content">
            <div className="bys-content-inner-section">
              <h2 className="bys-h2">
                YURT RENTALS
              </h2>
              <p className="bys-p">
                Escape to the serene beauty of the Pacific Northwest and immerse
                yourself in a unique glamping experience with our Mongolian yurts.
                Nestled in nature&#39;s embrace, our yurts provide a perfect blend of
                rustic charm and modern comfort, offering an unforgettable stay for
                both adventure seekers and those looking to unwind.
              </p>
              <div className="bys-button-container">
                <a href="https://59306_1.holidayfuture.com/" target="_blank">
                  <button className="bys-btn">
                    Book Now
                    <span className="bys-svg-container">
                      <svg data-bbox="19.999 58 160.001 84" viewBox="0 0 200 200" height="22" width="22" xmlns="http://www.w3.org/2000/svg" data-type="shape">
                        <g transform="rotate(90 100 100)">
                          <path d="M172.5 142a7.485 7.485 0 0 1-5.185-2.073L100 75.808l-67.315 64.12c-2.998 2.846-7.74 2.744-10.606-.234a7.454 7.454 0 0 1 .235-10.565l72.5-69.057a7.524 7.524 0 0 1 10.371 0l72.5 69.057a7.455 7.455 0 0 1 .235 10.565A7.503 7.503 0 0 1 172.5 142z" fill="white" />
                        </g>
                      </svg>
                    </span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="bys-section">
          <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/9qwi6DZE3P8PKV7E_regular.png" alt="Private Events" className="bys-image" />
          <div className="bys-content">
            <div className="bys-content-inner-section">
              <h2 className="bys-h2">
                PRIVATE EVENTS
              </h2>
              <p className="bys-p">
                Celebrate your special moments at Wildfern Grove. Perfect for
                weddings, birthdays, dance or yoga retreats, and more, our versatile
                event spaces offer a magical backdrop tailored to your vision. Enjoy
                our 30-foot yurt, scenic meadow, cedar hot tub, and more when you
                book the entire space for your event.
              </p>
              <div className="bys-button-container">
                <a className="bys-btn" href="https://forms.gle/R4XNKwP1ESCnZYvj6" target="_blank" noopener="" noreferrer="">
                  Check Availability
                  <span className="bys-svg-container">
                    <svg data-bbox="19.999 58 160.001 84" viewBox="0 0 200 200" height="22" width="22" xmlns="http://www.w3.org/2000/svg" data-type="shape">
                      <g transform="rotate(90 100 100)">
                        <path d="M172.5 142a7.485 7.485 0 0 1-5.185-2.073L100 75.808l-67.315 64.12c-2.998 2.846-7.74 2.744-10.606-.234a7.454 7.454 0 0 1 .235-10.565l72.5-69.057a7.524 7.524 0 0 1 10.371 0l72.5 69.057a7.455 7.455 0 0 1 .235 10.565A7.503 7.503 0 0 1 172.5 142z" fill="white" />
                      </g>
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// id: "e8f60479-4be7-4c5b-9380-35ec34543911"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "e23451af-2ef2-4c99-8fb2-547be4031a40"
export const ContentSlugE8f604794be74c5b938035ec34543911 = "home-hero-heading";

// id: "1bedbca9-7534-442b-8540-35813b6c120f"
// title: ""
// type: :reference
// key: "header"
// parent_id: "4c857ec3-21b3-420b-ad91-2974fb2a308d"
export function Header1bedbca97534442b854035813b6c120f(props: any) {
  return (
    <SecondaryMarketingHeader686cd6409234470aBb64A5fd4ced2cf2 parentTag="Header1bedbca97534442b854035813b6c120f" title="About Us" {...props} />
  );
}

// id: "5340cf7b-695a-493e-bf15-7c131d8f4b62"
// title: "Book Your Stay"
// type: :html
// key: "book-stay"
// parent_id: "af1c952a-0f0a-4309-b58a-1b4ae2ba4541"
export function BookStay5340cf7b695a493eBf157c131d8f4b62(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} BookStay5340cf7b695a493eBf157c131d8f4b62`}>
      <div className="book-text-block">
        <a id="book-your-stay">
        </a>
        <h2>
          Book Your Stay
        </h2>
        {props["book-stay-text"] || <ContentSnippet parentTag={buildClassName("BookStayTextC204d6e9D5d24eb0Abde3aeaa0b33983", parentTag)} contentSlug="home-book-stay" {...props} />}
      </div>
    </div>
  );
}

// id: "0ad1f810-aaf3-42dd-b417-cc3766b1fc0f"
// title: ""
// type: :text
// key: "title"
// parent_id: "21ac267f-9ee1-4c7a-a5b3-8a7e54afbbea"
export const Title0ad1f810Aaf342ddB417Cc3766b1fc0f = "Join Us";

// id: "fc86e28d-47cf-4db2-8d4d-59cd62366405"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "5049dfbf-a5cb-447c-8e38-17258abde62d"
export function HeaderTopFc86e28d47cf4db28d4d59cd62366405(props: any) {
  return (
    <DefaultHeaderTop3f0fe05dC0e2438cA8caC181594934a0 parentTag="HeaderTopFc86e28d47cf4db28d4d59cd62366405" {...props} />
  );
}

// id: "f256d63c-9ce8-4aae-a853-74bbbed8508a"
// title: ""
// type: :text
// key: "label"
// parent_id: "ecfc6683-bbab-4b32-89bb-dac3194851c9"
export const LabelF256d63c9ce84aaeA85374bbbed8508a = "Join Us";

// id: "e23451af-2ef2-4c99-8fb2-547be4031a40"
// title: ""
// type: :gf_preset
// key: "heading"
// parent_id: "70e59959-7f80-4b30-8290-23ef8d1dd975"
export function HeadingE23451af2ef24c998fb2547be4031a40(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet parentTag={buildClassName("HeadingE23451af2ef24c998fb2547be4031a40", parentTag)} contentSlug="home-hero-heading" {...props} />
  );
}

// id: "5d788ca8-2387-45a0-920c-d6f9200a4fa1"
// title: ""
// type: :text
// key: "title"
// parent_id: "f673dd3b-cdfd-44a2-b99f-7b3220f142d4"
export const Title5d788ca8238745a0920cD6f9200a4fa1 = "Secondary Page";

// id: "ad477503-9b5a-42df-b71f-4ce0a2566aff"
// title: ""
// type: :gf_preset
// key: "description-heading"
// parent_id: "70e59959-7f80-4b30-8290-23ef8d1dd975"
export function DescriptionHeadingAd4775039b5a42dfB71f4ce0a2566aff(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet parentTag={buildClassName("DescriptionHeadingAd4775039b5a42dfB71f4ce0a2566aff", parentTag)} contentSlug="hero-description-heading" {...props} />
  );
}

// id: "45d53ba7-0bdf-4300-aec4-8fe7767511c3"
// title: "about-description"
// type: :html
// key: "about-description"
// parent_id: "93936940-d953-41ba-9d01-73041fb950b2"
export function AboutDescription45d53ba70bdf4300Aec48fe7767511c3(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutDescription45d53ba70bdf4300Aec48fe7767511c3`}>
      {props["about-hero-heading"] || <ContentSnippet parentTag={buildClassName("AboutHeroHeadingC8000f3283b64f1a9bf90a56e6a777f6", parentTag)} contentSlug="about-hero-heading" {...props} />}
    </div>
  );
}

// id: "2ef8ac52-b82a-4594-8136-fc2feb65b642"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "ad477503-9b5a-42df-b71f-4ce0a2566aff"
export const ContentSlug2ef8ac52B82a45948136Fc2feb65b642 = "hero-description-heading";

// id: "9861ee57-e61e-4c10-bf57-e49764c2e0a9"
// title: ""
// type: :gf_preset
// key: "description-para"
// parent_id: "70e59959-7f80-4b30-8290-23ef8d1dd975"
export function DescriptionPara9861ee57E61e4c10Bf57E49764c2e0a9(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet parentTag={buildClassName("DescriptionPara9861ee57E61e4c10Bf57E49764c2e0a9", parentTag)} contentSlug="hero-description-para" {...props} />
  );
}

// id: "ff8bb59b-1bbb-40e8-b8a6-7bdd05a95a74"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "9861ee57-e61e-4c10-bf57-e49764c2e0a9"
export const ContentSlugFf8bb59b1bbb40e8B8a67bdd05a95a74 = "hero-description-para";

// id: "93936940-d953-41ba-9d01-73041fb950b2"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHero93936940D95341ba9d0173041fb950b2(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHero93936940D95341ba9d0173041fb950b2 hero`}>
      <h1>
        Our Story
      </h1>
      {props["about-description"] || <AboutDescription45d53ba70bdf4300Aec48fe7767511c3 />}
    </div>
  );
}

// id: "9638d87f-3dcd-4a91-b346-09fd773fc18a"
// title: ""
// type: :gf_preset
// key: "about-description"
// parent_id: "3aae3523-fce2-49dd-97f0-fc60c7f9df8a"
export function AboutDescription9638d87f3dcd4a91B34609fd773fc18a(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet parentTag={buildClassName("AboutDescription9638d87f3dcd4a91B34609fd773fc18a", parentTag)} contentSlug="about-hero-heading" {...props} />
  );
}

// id: "43037c76-3d2d-407a-8366-ed747f8eaeca"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "9638d87f-3dcd-4a91-b346-09fd773fc18a"
export const ContentSlug43037c763d2d407a8366Ed747f8eaeca = "about-hero-heading";

// id: "3aae3523-fce2-49dd-97f0-fc60c7f9df8a"
// title: ""
// type: :html
// key: "about-hero-1"
// parent_id: "4c857ec3-21b3-420b-ad91-2974fb2a308d"
export function AboutHero13aae3523Fce249dd97f0Fc60c7f9df8a(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutHero13aae3523Fce249dd97f0Fc60c7f9df8a`}>
      <h1>
        Our Story
      </h1>
      <div className="about-description">
        {props["about-description"] || <ContentSnippet parentTag={buildClassName("AboutDescription9638d87f3dcd4a91B34609fd773fc18a", parentTag)} contentSlug="about-hero-heading" {...props} />}
      </div>
    </div>
  );
}

// id: "0e08f7d5-8a23-455a-8fdf-356942dfa104"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePage0e08f7d58a23455a8fdf356942dfa104(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePage0e08f7d58a23455a8fdf356942dfa104 gf-page-layout`}>
      {props["header"] || <Header5049dfbfA5cb447c8e3817258abde62d />}
      <main>
        {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack7be49b4292f14b8499b60a7367b20e6f", parentTag)} {...props} />}
        <div className="services-title" id="choose-your-own-adventure">
          <h2>
            Choose your own adventure at Wildfern Grove
          </h2>
        </div>
        <div>
          {props["services-posts"] || <MarketingCards parentTag={buildClassName("ServicesPostsF94eb81dCc8a44ac854aCb654f58a20d", parentTag)} postSlug="home-page-offerings" {...props} />}
        </div>
      </main>
      {props["footer"] || <DefaultFooter380cec6cC44443a1819cE83be4ba3649 parentTag="Footer9fa6603b98354d9783e64986db1835f0" {...props} />}
    </div>
  );
}

// id: "b9e7e289-42e7-4074-83ec-ffc5b4843066"
// title: ""
// type: :html
// key: "body"
// parent_id: "4c857ec3-21b3-420b-ad91-2974fb2a308d"
export function BodyB9e7e28942e7407483ecFfc5b4843066(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <main className={`${parentTag || ""} BodyB9e7e28942e7407483ecFfc5b4843066`}>
      {props["about-marketing-cards"] || <MarketingCards parentTag={buildClassName("AboutMarketingCards066453d068844ca2Bbb0Cc213d11bdd7", parentTag)} imageAs="background" postSlug="about-us-posts" {...props} />}
    </main>
  );
}

// id: "64de7f10-5f80-4277-a420-1f69673e59bc"
// title: ""
// type: :text
// key: "imageAs"
// parent_id: "066453d0-6884-4ca2-bbb0-cc213d11bdd7"
export const ImageAs64de7f105f804277A4201f69673e59bc = "background";

// id: "8a316de1-e97f-420f-a698-d5459e6cac3d"
// title: ""
// type: :reference
// key: "header"
// parent_id: "f4d7bb99-76d1-4c32-b581-c6c7e44b32b8"
export function Header8a316de1E97f420fA698D5459e6cac3d(props: any) {
  return (
    <SecondaryMarketingHeader686cd6409234470aBb64A5fd4ced2cf2 parentTag="Header8a316de1E97f420fA698D5459e6cac3d" {...props} />
  );
}

// id: "af1c952a-0f0a-4309-b58a-1b4ae2ba4541"
// title: "Home Page old"
// type: :html
// key: "home-page-1"
// parent_id: nil
export function HomePage1Af1c952a0f0a4309B58a1b4ae2ba4541(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePage1Af1c952a0f0a4309B58a1b4ae2ba4541 gf-page-layout`}>
      {props["header"] || <HomeHeaderC4d7847430f249cb8171E6c1a78421e5 parentTag="HeaderAad8c78748414f309bd1Ed4b3b066e9a" {...props} />}
      <main>
        {props["welcome-message-row"] || <WelcomeMessageRow99bb88a982424e4e9f0251a826ceddac />}
        {props["book-stay"] || <BookStay5340cf7b695a493eBf157c131d8f4b62 />}
        {props["services-posts"] || <MarketingCards parentTag={buildClassName("ServicesPosts50f6289b37e141f59cc241d4a4805fab", parentTag)} postSlug="home-page-offerings" {...props} />}
      </main>
      {props["footer"] || <DefaultFooter380cec6cC44443a1819cE83be4ba3649 parentTag="FooterA801be3f6f1c485c8bac34b897c6f1bd" {...props} />}
    </div>
  );
}

// id: "f5fa5def-8ca8-4215-9afb-bc4c3b60a1e9"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "f4d7bb99-76d1-4c32-b581-c6c7e44b32b8"
export function FooterF5fa5def8ca842159afbBc4c3b60a1e9(props: any) {
  return (
    <DefaultFooter380cec6cC44443a1819cE83be4ba3649 parentTag="FooterF5fa5def8ca842159afbBc4c3b60a1e9" {...props} />
  );
}

// id: "70e59959-7f80-4b30-8290-23ef8d1dd975"
// title: ""
// type: :html
// key: "hero"
// parent_id: "5049dfbf-a5cb-447c-8e38-17258abde62d"
export function Hero70e599597f804b30829023ef8d1dd975(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <section className={`${parentTag || ""} Hero70e599597f804b30829023ef8d1dd975 `}>
      <div className="hero-content">
        <div className="homepage-forest-backdrop">
          <div className="homepage-sanctuary-title">
            <div className="hero-title-innerContainer">
              {props["heading"] || <ContentSnippet parentTag={buildClassName("HeadingE23451af2ef24c998fb2547be4031a40", parentTag)} contentSlug="home-hero-heading" {...props} />}
            </div>
            <div className="homepage-action-buttons">
              <a href="https://59306_1.holidayfuture.com/">
                <button className="homepage-button homepage-reserve-button">
                  Reserve Your Yurt
                </button>
              </a>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLScEjRR6GT_l2SNHEQMWW12nIy17FxVZeZ0WDVamxCifl5j13A/viewform">
                <button className="homepage-button homepage-host-button">
                  Host Your Private Event
                </button>
              </a>
            </div>
          </div>
          <div className="homepage-main-content">
            <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/y560e3L1XPuas_r-_file.svg" alt="Yurt in Forest" className="homepage-yurt-image" />
          </div>
        </div>
        <div className="hero-banner-description-container">
          <div className="hero-banner-description-Innercontainer">
            <div className="description-heading-container">
              <h1 className="description-heading">
                {props["description-heading"] || <ContentSnippet parentTag={buildClassName("DescriptionHeadingAd4775039b5a42dfB71f4ce0a2566aff", parentTag)} contentSlug="hero-description-heading" {...props} />}
              </h1>
            </div>
            <div className="description-para-container">
              <p>
                {props["description-para"] || <ContentSnippet parentTag={buildClassName("DescriptionPara9861ee57E61e4c10Bf57E49764c2e0a9", parentTag)} contentSlug="hero-description-para" {...props} />}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// id: "091c6361-e3d9-4eb9-b408-5a3aa216fd75"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCards091c6361E3d94eb9B4085a3aa216fd75(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCards091c6361E3d94eb9B4085a3aa216fd75", parentTag)} postSlug="home-page-posts2" buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" {...props} />
  );
}

// id: "3f0fe05d-c0e2-438c-a8ca-c181594934a0"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTop3f0fe05dC0e2438cA8caC181594934a0(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTop3f0fe05dC0e2438cA8caC181594934a0 header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogoA93127c8E1be43fdA274969971299572", parentTag)} url="/" label={<LabelFd6424bbEbfd4002B8b9Fdd9e717ddad />} className="linked-logo" {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation9f3dc81fA2a84cde83c336bd8fe3e18a", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />}
    </div>
  );
}

// id: "0f25ec69-08d2-41c3-8078-d01f25fda51d"
// title: "Content Slug"
// type: :text
// key: "contentSlug"
// parent_id: nil
export const ContentSlug0f25ec6908d241c38078D01f25fda51d = "home-hero-heading";

// id: "55997bef-896b-4f0f-bb08-3ddd0189445b"
// title: ""
// type: :gf_preset
// key: "heading"
// parent_id: "ff358daf-8f96-4ffe-9b77-5edb5fdb83d1"
export function Heading55997bef896b4f0fBb083ddd0189445b(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet parentTag={buildClassName("Heading55997bef896b4f0fBb083ddd0189445b", parentTag)} contentSlug="home-hero-heading" {...props} />
  );
}

// id: "0d53c92a-733e-420a-9476-63fd09c1d39c"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "55997bef-896b-4f0f-bb08-3ddd0189445b"
export const ContentSlug0d53c92a733e420a947663fd09c1d39c = "home-hero-heading";

// id: "08105494-e755-461b-8cad-9417087daf09"
// title: ""
// type: :gf_preset
// key: "welcome-message"
// parent_id: "99bb88a9-8242-4e4e-9f02-51a826ceddac"
export function WelcomeMessage08105494E755461b8cad9417087daf09(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet parentTag={buildClassName("WelcomeMessage08105494E755461b8cad9417087daf09", parentTag)} contentSlug="home-welcome-message" {...props} />
  );
}

// id: "efed6ad5-b613-4882-a26a-eddbb2fe04e3"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeaderEfed6ad5B6134882A26aEddbb2fe04e3(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeaderEfed6ad5B6134882A26aEddbb2fe04e3 header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo88b68c32034f4b21B4f8E8133508699a", parentTag)} label={<DefaultLogo2586ef229f5f4fe4A7b324a7054e77e2 parentTag="Label43e9b759855e486aAe9e6b96a6277275" alt="logo" />} {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation1278ba8558ec4818Aac3F19b0beb1dc0", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
    </div>
  );
}

// id: "b952cb74-510b-4279-9490-ed764da366e7"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "08105494-e755-461b-8cad-9417087daf09"
export const ContentSlugB952cb74510b42799490Ed764da366e7 = "home-welcome-message";

// id: "28d5934c-8dde-4a7c-a9f5-202705f94d48"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBody28d5934c8dde4a7cA9f5202705f94d48(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBody28d5934c8dde4a7cA9f5202705f94d48 page-body`}>
      {props["marketingCards"] || <MarketingCards091c6361E3d94eb9B4085a3aa216fd75 parentTag="MarketingCardsC36fc941A07e4d86B94033ed0fb8371e" postSlug="home-page-posts2" {...props} />}
    </div>
  );
}

// id: "c204d6e9-d5d2-4eb0-abde-3aeaa0b33983"
// title: ""
// type: :gf_preset
// key: "book-stay-text"
// parent_id: "5340cf7b-695a-493e-bf15-7c131d8f4b62"
export function BookStayTextC204d6e9D5d24eb0Abde3aeaa0b33983(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet parentTag={buildClassName("BookStayTextC204d6e9D5d24eb0Abde3aeaa0b33983", parentTag)} contentSlug="home-book-stay" {...props} />
  );
}

// id: "c9c4884c-2173-4f05-9b0b-331d9987415d"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "c204d6e9-d5d2-4eb0-abde-3aeaa0b33983"
export const ContentSlugC9c4884c21734f059b0b331d9987415d = "home-book-stay";

// id: "50f6289b-37e1-41f5-9cc2-41d4a4805fab"
// title: "Services"
// type: :gf_preset
// key: "services-posts"
// parent_id: "af1c952a-0f0a-4309-b58a-1b4ae2ba4541"
export function ServicesPosts50f6289b37e141f59cc241d4a4805fab(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("ServicesPosts50f6289b37e141f59cc241d4a4805fab", parentTag)} postSlug="home-page-offerings" {...props} />
  );
}

// id: "0ec32170-f660-45af-b17f-2747b36724e2"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "50f6289b-37e1-41f5-9cc2-41d4a4805fab"
export const PostSlug0ec32170F66045afB17f2747b36724e2 = "home-page-offerings";

// id: "686cd640-9234-470a-bb64-a5fd4ced2cf2"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeader686cd6409234470aBb64A5fd4ced2cf2(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeader686cd6409234470aBb64A5fd4ced2cf2 site-header`}>
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop3f0fe05dC0e2438cA8caC181594934a0 parentTag="HeaderTop3a272821E8a64ba28e5e0b2a10604ba2" {...props} />}
      </div>
    </div>
  );
}

// id: "714fcbd3-e145-42be-b71c-8ed0d64e21d2"
// title: "about-hero"
// type: :reference
// key: "about-hero"
// parent_id: "4c857ec3-21b3-420b-ad91-2974fb2a308d"
export function AboutHero714fcbd3E14542beB71c8ed0d64e21d2(props: any) {
  return (
    <SecondaryMarketingHero93936940D95341ba9d0173041fb950b2 parentTag="AboutHero714fcbd3E14542beB71c8ed0d64e21d2" {...props} />
  );
}

// id: "4c857ec3-21b3-420b-ad91-2974fb2a308d"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPage4c857ec321b3420bAd912974fb2a308d(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPage4c857ec321b3420bAd912974fb2a308d gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeader686cd6409234470aBb64A5fd4ced2cf2 parentTag="Header1bedbca97534442b854035813b6c120f" title="About Us" {...props} />}
      {props["about-hero-1"] || <AboutHero13aae3523Fce249dd97f0Fc60c7f9df8a />}
      {props["body"] || <BodyB9e7e28942e7407483ecFfc5b4843066 />}
      {props["footer"] || <DefaultFooter380cec6cC44443a1819cE83be4ba3649 parentTag="Footer566e273c46da4b09Bb6e044392ea24a6" {...props} />}
    </div>
  );
}

// id: "99bb88a9-8242-4e4e-9f02-51a826ceddac"
// title: "Welcome Message Row"
// type: :html
// key: "welcome-message-row"
// parent_id: "af1c952a-0f0a-4309-b58a-1b4ae2ba4541"
export function WelcomeMessageRow99bb88a982424e4e9f0251a826ceddac(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <section className={`${parentTag || ""} WelcomeMessageRow99bb88a982424e4e9f0251a826ceddac`} id="welcome-main-section">
      <div className="welcome-inner">
        <div className="welcome-image-container">
          <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/Nlke7kIRFMpplTZM_regular.png" />
        </div>
        <div className="welcome-text-block">
          {props["welcome-message"] || <ContentSnippet parentTag={buildClassName("WelcomeMessage08105494E755461b8cad9417087daf09", parentTag)} contentSlug="home-welcome-message" {...props} />}
          <div className="button-container">
            <a className="welcome-button transition" href="/about">
              <span>
                Read More
              </span>
              <span className="svg-container">
                <img src="https://mp1md-pub.s3.amazonaws.com/orgs/wildfern/right-arrow.svg" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

// id: "f2bbf7e1-0df4-4f36-a90f-18ff98d801b7"
// title: "about-hero-heading"
// type: :text
// key: "contentSlug"
// parent_id: nil
export const ContentSlugF2bbf7e10df44f36A90f18ff98d801b7 = "about-hero-heading";

// id: "c8000f32-83b6-4f1a-9bf9-0a56e6a777f6"
// title: "about hero heading"
// type: :gf_preset
// key: "about-hero-heading"
// parent_id: "45d53ba7-0bdf-4300-aec4-8fe7767511c3"
export function AboutHeroHeadingC8000f3283b64f1a9bf90a56e6a777f6(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet parentTag={buildClassName("AboutHeroHeadingC8000f3283b64f1a9bf90a56e6a777f6", parentTag)} contentSlug="about-hero-heading" {...props} />
  );
}

// id: "78639e87-0dfa-4c29-9dac-bcc56e8ab1c0"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "c8000f32-83b6-4f1a-9bf9-0a56e6a777f6"
export const ContentSlug78639e870dfa4c299dacBcc56e8ab1c0 = "about-hero-heading";

// id: "066453d0-6884-4ca2-bbb0-cc213d11bdd7"
// title: "About Page Marketing Cards"
// type: :gf_preset
// key: "about-marketing-cards"
// parent_id: "b9e7e289-42e7-4074-83ec-ffc5b4843066"
export function AboutMarketingCards066453d068844ca2Bbb0Cc213d11bdd7(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("AboutMarketingCards066453d068844ca2Bbb0Cc213d11bdd7", parentTag)} imageAs="background" postSlug="about-us-posts" {...props} />
  );
}

// id: "92a5090e-1d5a-4e3b-a2aa-a124690c5c33"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "066453d0-6884-4ca2-bbb0-cc213d11bdd7"
export const PostSlug92a5090e1d5a4e3bA2aaA124690c5c33 = "about-us-posts";

// id: "b90a63cd-c0c7-4486-bd1f-eb0723b0cff4"
// title: ""
// type: :html
// key: "services"
// parent_id: "af1c952a-0f0a-4309-b58a-1b4ae2ba4541"
export function ServicesB90a63cdC0c74486Bd1fEb0723b0cff4(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <section className={`${parentTag || ""} ServicesB90a63cdC0c74486Bd1fEb0723b0cff4`}>
      <div className="bys-container">
        <div className="bys-section">
          <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/SwOg496DLJdgcrHn_regular.png" alt="Yurt Rentals" className="bys-image" />
          <div className="bys-content">
            <div className="bys-content-inner-section">
              <h2 className="bys-h2">
                YURT RENTALS
              </h2>
              <p className="bys-p">
                Escape to the serene beauty of the Pacific Northwest and immerse
                yourself in a unique glamping experience with our Mongolian yurts.
                Nestled in nature&#39;s embrace, our yurts provide a perfect blend of
                rustic charm and modern comfort, offering an unforgettable stay for
                both adventure seekers and those looking to unwind.
              </p>
              <div className="bys-button-container">
                <a href="https://59306_1.holidayfuture.com/" target="_blank">
                  <button className="bys-btn">
                    Book Now
                    <span className="bys-svg-container">
                      <svg data-bbox="19.999 58 160.001 84" viewBox="0 0 200 200" height="22" width="22" xmlns="http://www.w3.org/2000/svg" data-type="shape">
                        <g transform="rotate(90 100 100)">
                          <path d="M172.5 142a7.485 7.485 0 0 1-5.185-2.073L100 75.808l-67.315 64.12c-2.998 2.846-7.74 2.744-10.606-.234a7.454 7.454 0 0 1 .235-10.565l72.5-69.057a7.524 7.524 0 0 1 10.371 0l72.5 69.057a7.455 7.455 0 0 1 .235 10.565A7.503 7.503 0 0 1 172.5 142z" fill="white" />
                        </g>
                      </svg>
                    </span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="bys-section">
          <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/9qwi6DZE3P8PKV7E_regular.png" alt="Private Events" className="bys-image" />
          <div className="bys-content">
            <div className="bys-content-inner-section">
              <h2 className="bys-h2">
                PRIVATE EVENTS
              </h2>
              <p className="bys-p">
                Celebrate your special moments at Wildfern Grove. Perfect for
                weddings, birthdays, dance or yoga retreats, and more, our versatile
                event spaces offer a magical backdrop tailored to your vision. Enjoy
                our 30-foot yurt, scenic meadow, cedar hot tub, and more when you
                book the entire space for your event.
              </p>
              <div className="bys-button-container">
                <a className="bys-btn" href="https://forms.gle/R4XNKwP1ESCnZYvj6" target="_blank" noopener="" noreferrer="">
                  Check Availability
                  <span className="bys-svg-container">
                    <svg data-bbox="19.999 58 160.001 84" viewBox="0 0 200 200" height="22" width="22" xmlns="http://www.w3.org/2000/svg" data-type="shape">
                      <g transform="rotate(90 100 100)">
                        <path d="M172.5 142a7.485 7.485 0 0 1-5.185-2.073L100 75.808l-67.315 64.12c-2.998 2.846-7.74 2.744-10.606-.234a7.454 7.454 0 0 1 .235-10.565l72.5-69.057a7.524 7.524 0 0 1 10.371 0l72.5 69.057a7.455 7.455 0 0 1 .235 10.565A7.503 7.503 0 0 1 172.5 142z" fill="white" />
                      </g>
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// id: "2907b4a4-4368-4c8f-b402-4dab96f7c1ea"
// title: "Contact"
// type: :html
// key: "contact"
// parent_id: "380cec6c-c444-43a1-819c-e83be4ba3649"
export function Contact2907b4a443684c8fB4024dab96f7c1ea(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} Contact2907b4a443684c8fB4024dab96f7c1ea`}>
      <a className="info-item" href="https://www.google.com/maps/place/Buckley,+WA/@47.1624222,-122.0468909,14z/data=!3m1!4b1!4m6!3m5!1s0x5490e56a3ca1a827:0x1b3782310c07e03d!8m2!3d47.1631573!4d-122.0267787!16zL20vMDEwc3Yy?entry=ttu" noreferrer="" noopener="" target="_blank">
        <svg preserveAspectRatio="xMidYMid meet" data-bbox="53.5 36.5 93 127" viewBox="53.5 36.5 93 127" height="200" width="200" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true" aria-label="">
          <defs>
          </defs>
          <g>
            <path d="M99.999 163.5l-3.25-3.895C94.986 157.487 53.5 107.468 53.5 82.916 53.5 57.323 74.359 36.5 99.999 36.5c25.644 0 46.501 20.823 46.501 46.416 0 24.551-41.483 74.571-43.252 76.688l-3.249 3.896zm0-118.56c-20.978 0-38.046 17.036-38.046 37.977 0 16.359 25.019 51.015 38.046 67.305 13.029-16.29 38.048-50.946 38.048-67.305 0-20.942-17.068-37.977-38.048-37.977z" fill="currentColor" data-color="1" />
            <path d="M99.999 101.658c-10.351 0-18.775-8.407-18.775-18.741 0-10.335 8.424-18.743 18.775-18.743 10.353 0 18.777 8.408 18.777 18.743 0 10.333-8.424 18.741-18.777 18.741zm0-29.046c-5.69 0-10.32 4.621-10.32 10.304 0 5.68 4.63 10.303 10.32 10.303 5.692 0 10.324-4.622 10.324-10.303 0-5.682-4.632-10.304-10.324-10.304z" fill="currentColor" data-color="1" />
          </g>
        </svg>
        <span>
          Buckley, Washington
        </span>
      </a>
      <a className="info-item" href="mailto:wildferngrove@gmail.com" noreferrer="" noopener="" target="_blank">
        <svg preserveAspectRatio="xMidYMid meet" data-bbox="35 56 130 88" viewBox="35 56 130 88" height="200" width="200" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true" aria-label="">
          <g>
            <path d="M35 56v88h130V56H35zm64.879 58.87L49.15 64.499h101.455L99.879 114.87zM72.191 99.311l-28.755 30.025V70.757l28.755 28.554zm6.009 5.967l21.679 21.525 21.677-21.525 28.93 30.224H49.254L78.2 105.278zm49.364-5.967l29-28.796v59.092l-29-30.296z" fill="currentColor" data-color="1" />
          </g>
        </svg>
        <span>
          wildferngrove@gmail.com
        </span>
      </a>
    </div>
  );
}

// id: "04814c03-06f9-423b-b842-5dbb670fd760"
// title: "Social Links"
// type: :html
// key: "social"
// parent_id: "380cec6c-c444-43a1-819c-e83be4ba3649"
export function Social04814c0306f9423bB8425dbb670fd760(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} Social04814c0306f9423bB8425dbb670fd760 flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.facebook.com/profile.php?id=61555136205304">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.instagram.com/wildfern_grove">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "380cec6c-c444-43a1-819c-e83be4ba3649"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooter380cec6cC44443a1819cE83be4ba3649(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooter380cec6cC44443a1819cE83be4ba3649 footer`}>
      {props["social"] || <Social04814c0306f9423bB8425dbb670fd760 />}
      {props["links"] || <Navigation parentTag={buildClassName("Links41b87e7cE5e74984A6edAc14a7f40a1f", parentTag)} navMenuSlug="footer-nav" {...props} />}
      {props["contact"] || <Contact2907b4a443684c8fB4024dab96f7c1ea />}
      <div className="spacing">
      </div>
      {props["site-logo-name"] || <SiteLogoName0ec8d55a7e894c91B125402d5c88c736 />}
      {props["copyright"] || <GroupCopyright parentTag={buildClassName("Copyright2c5d641979184f04B365A2f3c7e9adc1", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("Groupflow9bfcbc18136141eeBec7686ffed34435", parentTag)} {...props} />}
    </div>
  );
}

// id: "5049dfbf-a5cb-447c-8e38-17258abde62d"
// title: ""
// type: :html
// key: "header"
// parent_id: "0e08f7d5-8a23-455a-8fdf-356942dfa104"
export function Header5049dfbfA5cb447c8e3817258abde62d(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} Header5049dfbfA5cb447c8e3817258abde62d site-header`}>
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop3f0fe05dC0e2438cA8caC181594934a0 parentTag="HeaderTopFc86e28d47cf4db28d4d59cd62366405" {...props} />}
      </div>
      {props["hero"] || <Hero70e599597f804b30829023ef8d1dd975 />}
    </div>
  );
}

// id: "9fa6603b-9835-4d97-83e6-4986db1835f0"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "0e08f7d5-8a23-455a-8fdf-356942dfa104"
export function Footer9fa6603b98354d9783e64986db1835f0(props: any) {
  return (
    <DefaultFooter380cec6cC44443a1819cE83be4ba3649 parentTag="Footer9fa6603b98354d9783e64986db1835f0" {...props} />
  );
}

// id: "7be49b42-92f1-4b84-99b6-0a7367b20e6f"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "0e08f7d5-8a23-455a-8fdf-356942dfa104"
export function WelcomeBack7be49b4292f14b8499b60a7367b20e6f(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack7be49b4292f14b8499b60a7367b20e6f", parentTag)} {...props} />
  );
}

// id: "22cc36cf-ea37-4c68-a82c-befacce33f04"
// title: ""
// type: :reference
// key: "body"
// parent_id: "0e08f7d5-8a23-455a-8fdf-356942dfa104"
export function Body22cc36cfEa374c68A82cBefacce33f04(props: any) {
  return (
    <MarketingBody28d5934c8dde4a7cA9f5202705f94d48 parentTag="Body22cc36cfEa374c68A82cBefacce33f04" {...props} />
  );
}

// id: "0eeea73f-0a41-457f-b340-25d950ecce3a"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "0e08f7d5-8a23-455a-8fdf-356942dfa104"
export function Quote0eeea73f0a41457fB34025d950ecce3a(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote parentTag={buildClassName("Quote0eeea73f0a41457fB34025d950ecce3a", parentTag)} {...props} />
  );
}

// id: "3612cc05-ad1b-455f-9de9-34919afa0418"
// title: "Book Your Stay Clone 9VDbF9OqW0rbeY4"
// type: :html
// key: "book-stay"
// parent_id: "0e08f7d5-8a23-455a-8fdf-356942dfa104"
export function BookStay3612cc05Ad1b455f9de934919afa0418(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} BookStay3612cc05Ad1b455f9de934919afa0418`}>
      <div className="book-text-block">
        <a id="book-your-stay">
        </a>
        <h2>
          Book Your Stay
        </h2>
        {props["book-stay-text"] || <ContentSnippet parentTag={buildClassName("BookStayTextE4229715695948419cae532da68cadd4", parentTag)} contentSlug="home-book-stay" {...props} />}
      </div>
    </div>
  );
}

// id: "f94eb81d-cc8a-44ac-854a-cb654f58a20d"
// title: "Services Clone ebTvTvYIRBhR5Aa"
// type: :gf_preset
// key: "services-posts"
// parent_id: "0e08f7d5-8a23-455a-8fdf-356942dfa104"
export function ServicesPostsF94eb81dCc8a44ac854aCb654f58a20d(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("ServicesPostsF94eb81dCc8a44ac854aCb654f58a20d", parentTag)} postSlug="home-page-offerings" {...props} />
  );
}

// id: "92085fc7-2215-4d44-8fd3-633053b6e2e0"
// title: "Welcome Message Row Clone WVIbLQCkLsvvdbI"
// type: :html
// key: "welcome-message-row"
// parent_id: "0e08f7d5-8a23-455a-8fdf-356942dfa104"
export function WelcomeMessageRow92085fc722154d448fd3633053b6e2e0(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <section className={`${parentTag || ""} WelcomeMessageRow92085fc722154d448fd3633053b6e2e0`} id="welcome-main-section">
      <div className="welcome-inner">
        <div className="welcome-image-container">
          <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/Nlke7kIRFMpplTZM_regular.png" />
        </div>
        <div className="welcome-text-block">
          {props["welcome-message"] || <ContentSnippet parentTag={buildClassName("WelcomeMessage5f9e10b2631141bd8be667db83672959", parentTag)} contentSlug="home-welcome-message" {...props} />}
          <div className="button-container">
            <a className="welcome-button transition" href="/about">
              <span>
                Read More
              </span>
              <span className="svg-container">
                <img src="https://mp1md-pub.s3.amazonaws.com/orgs/wildfern/right-arrow.svg" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

// id: "97257abe-9767-472e-a3ab-5a924046ef91"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "bf9e7a03-4a31-4fdd-91f1-28ac764ef149"
export const ContentSlug97257abe9767472eA3ab5a924046ef91 = "home-hero-heading";

const Components = {
  WelcomeMessage5f9e10b2631141bd8be667db83672959,
  ContentSlug317f5769E67d47c59bc21e37e2883825,
  BookStayTextE4229715695948419cae532da68cadd4,
  TitleE5944d02A52a45bcB5043b2f041b313d,
  BackgroundImage6d4ca5ac02224d7dA6801cf059cc78ed,
  Navigation1278ba8558ec4818Aac3F19b0beb1dc0,
  ImageUrl45a3cb3bB97345bbAd9922f3dab687b5,
  ContentSlugB9f3197834d84f509b9c98670a7ff46d,
  ContentSlug5e9b89a6B577461f9fdbE58ff27f0241,
  ResourcesPage0e76d6d4Fb744f91Bc2101b3f3b4f4cc,
  PostSlug2224f80d45ea4dd68e9124244b1d9872,
  SignupPages504aea07F5f448288e40Eb10b3408336,
  Header21ac267f9ee14c7aA5b38a7e54afbbea,
  HeaderAad8c78748414f309bd1Ed4b3b066e9a,
  MessagePages6273a6796d3443f58468502c0d79db7a,
  Title307366ccAfee44738efbF254855042f5,
  HeaderDd4c42d437b549c39460Fa92fdd54548,
  Title780ea8cb6ac446e2901305983db4d3be,
  AdminPages063dd63d8b2a4a038e0e9731df7f76c7,
  Hero791247ee67d64528Bfb6D14f13699592,
  PostSlug7494ae41Ee8c4291A1142e694a0f1e7b,
  SiteLogoName0ec8d55a7e894c91B125402d5c88c736,
  HeadingBf9e7a034a314fdd91f128ac764ef149,
  Label25a656d6B92046578910032c77dbdfc2,
  FooterA801be3f6f1c485c8bac34b897c6f1bd,
  HeaderD1428c7dEa2e4a10B9a7D7f8d02fce20,
  WidgetIdDe4c832555384a9dBf7478a45b8063ed,
  TitleEdf9e19d3dc04fd1B4af3fdd9c45ded3,
  DefaultHeader9b44de5321fa4be0B89bC24d7e0ee260,
  MembersAreaHeaderAdee4dbb7d5646c9910fB0c9ee29d322,
  MarketingCardsC36fc941A07e4d86B94033ed0fb8371e,
  Groupflow9bfcbc18136141eeBec7686ffed34435,
  PostSlug09cbfaa95a0b46e394e9F88328fcd135,
  Label43e9b759855e486aAe9e6b96a6277275,
  PageQuoteF1ff0d1e74924e899f5e91f4beb8a8cb,
  HeaderTop3a272821E8a64ba28e5e0b2a10604ba2,
  SecondaryNav3951e1987beb406eAd7f075b26037cd3,
  NavMenuSlug7dd46aa02edb450bB45273b6d94e9074,
  NavMenuSlugB5c59ab71801458dA0385214b7c2366a,
  PublicEventsPageA0e0fe94Fc6e4ad096a9B47d09582ee0,
  HomePageHeroFf358daf8f964ffe9b775edb5fdb83d1,
  AltC0a200f9F0294aa5A0ac55bd5407430b,
  Footer566e273c46da4b09Bb6e044392ea24a6,
  Url45e5d9615f6f42c9985e6057c26f8aef,
  LabelFd6424bbEbfd4002B8b9Fdd9e717ddad,
  JoinUsPageF4d7bb9976d14c32B581C6c7e44b32b8,
  HeaderTop936d918918114a208d559b8fee0a3169,
  LinkedLogoA93127c8E1be43fdA274969971299572,
  Class01be14438ba146b18b0c68c443eea87e,
  MembersAreaDefaultF41290b314ab46319c79Ca19f99028c5,
  FooterEb1cce77E3b546df8dd815c032359c19,
  Header2ac9b2511f504a5498c3004e78cfd50c,
  Title619c0a579afc45fcAbd2Bfce07a535fa,
  NavMenuSlugE6659071C44643879ecd804fe62938b0,
  Href9280fb554afb4d35Befc66d3dc76eaaf,
  HeaderF673dd3bCdfd44a2B99f7b3220f142d4,
  Hero730fe305Beb7431cBcda65a673c1c014,
  HeaderTopD1088d0cC2684d0d9cf6466f3a8de12e,
  Title7b5f62e9Def049e9Accd700643317c7a,
  DescriptionParaB73e778bB1bf487b857d567fe79f4acb,
  Footer54b24d2dFeb4425c94770a6e49f44ac1,
  ButtonClass7193e0a5B85c4317988e5ad42c82fd65,
  Copyright2c5d641979184f04B365A2f3c7e9adc1,
  BackgroundImage50eaa5e713ae450a973fFc65280e9229,
  NavMenuSlugCc86dc70Ad8e4086Ab2809db7625c52c,
  SecondaryPage18586940D8674d41Bab74a6504586fc0,
  ButtonEcfc6683Bbab4b3289bbDac3194851c9,
  Footer2640f31a405246b888d13739292eafb1,
  WelcomeBack8fd82fed771449db972c04f710d22590,
  NavMenuSlug636326cc96ee40e799338cda1cdc5538,
  AboutLevelingPage7be55bd9907b4b31820900f7e5470255,
  FooterFbd677498a264159B784B7b7e1d907ab,
  Header6c17edf8B4ec4711860eBa2254c585cd,
  Title406240bd2e8b4be3B86d16092ef9f2f2,
  HeaderF4066ea12c8b42adB9846e703b728376,
  Links41b87e7cE5e74984A6edAc14a7f40a1f,
  Header7c63619a489a4f2eA533Ebaf4cf1bf3c,
  Navigation9f3dc81fA2a84cde83c336bd8fe3e18a,
  Class5a033c3406c5481bAd58008b948475cc,
  DescriptionHeadingF324590a728e4712B2f60543aeedc157,
  MemberPages2ff9381c0e9e4ce384e14c62c79f6d5f,
  FooterC368e0a2613845c586d90407acaae605,
  LinkedLogo88b68c32034f4b21B4f8E8133508699a,
  Logo14ae2031994c4fa8Ba87001bda8c8b94,
  Title35b621656f1a451d987eDa519eaa2f3b,
  DefaultLogo2586ef229f5f4fe4A7b324a7054e77e2,
  BodyEd6aad53417848798bc77d7c226247c8,
  Quote61f8cbd7A03245eb8358D00f71acd838,
  WidgetId823f9d63A04d4ee5Bc56Cdad7cb809aa,
  HeroHome2796b8da7Fcab48a6Ba5aE69f437a5ba3,
  HomeHeaderC4d7847430f249cb8171E6c1a78421e5,
  Header9d8124778d5f425cB3c9E50c4e432972,
  Title46113fe504274f99B4ebB7029d9c41d6,
  ContentSlugF3c29add9ac044b386d5C398b4c1dfdb,
  Title6e97f00bF5414b07A7509a135c0a6251,
  ContentSlug4d342cbe727b4f3cA23d82bedc2b48c2,
  FooterEdf4fb9660864e158d184a00a8cf16aa,
  NavMenuSlugA3d2e1c8288d4b0988b2Fd2e6e532482,
  HeaderB5466e6e89a94b1895bfD051fbce2a28,
  HrefAe6674d7Bdfa4d9997137f37408216bf,
  ServicesE48a92a15ce945bf9114Fb3a4dead763,
  ContentSlugE8f604794be74c5b938035ec34543911,
  Header1bedbca97534442b854035813b6c120f,
  BookStay5340cf7b695a493eBf157c131d8f4b62,
  Title0ad1f810Aaf342ddB417Cc3766b1fc0f,
  HeaderTopFc86e28d47cf4db28d4d59cd62366405,
  LabelF256d63c9ce84aaeA85374bbbed8508a,
  HeadingE23451af2ef24c998fb2547be4031a40,
  Title5d788ca8238745a0920cD6f9200a4fa1,
  DescriptionHeadingAd4775039b5a42dfB71f4ce0a2566aff,
  AboutDescription45d53ba70bdf4300Aec48fe7767511c3,
  ContentSlug2ef8ac52B82a45948136Fc2feb65b642,
  DescriptionPara9861ee57E61e4c10Bf57E49764c2e0a9,
  ContentSlugFf8bb59b1bbb40e8B8a67bdd05a95a74,
  SecondaryMarketingHero93936940D95341ba9d0173041fb950b2,
  AboutDescription9638d87f3dcd4a91B34609fd773fc18a,
  ContentSlug43037c763d2d407a8366Ed747f8eaeca,
  AboutHero13aae3523Fce249dd97f0Fc60c7f9df8a,
  HomePage0e08f7d58a23455a8fdf356942dfa104,
  BodyB9e7e28942e7407483ecFfc5b4843066,
  ImageAs64de7f105f804277A4201f69673e59bc,
  Header8a316de1E97f420fA698D5459e6cac3d,
  HomePage1Af1c952a0f0a4309B58a1b4ae2ba4541,
  FooterF5fa5def8ca842159afbBc4c3b60a1e9,
  Hero70e599597f804b30829023ef8d1dd975,
  MarketingCards091c6361E3d94eb9B4085a3aa216fd75,
  DefaultHeaderTop3f0fe05dC0e2438cA8caC181594934a0,
  ContentSlug0f25ec6908d241c38078D01f25fda51d,
  Heading55997bef896b4f0fBb083ddd0189445b,
  ContentSlug0d53c92a733e420a947663fd09c1d39c,
  WelcomeMessage08105494E755461b8cad9417087daf09,
  ShortHeaderEfed6ad5B6134882A26aEddbb2fe04e3,
  ContentSlugB952cb74510b42799490Ed764da366e7,
  MarketingBody28d5934c8dde4a7cA9f5202705f94d48,
  BookStayTextC204d6e9D5d24eb0Abde3aeaa0b33983,
  ContentSlugC9c4884c21734f059b0b331d9987415d,
  ServicesPosts50f6289b37e141f59cc241d4a4805fab,
  PostSlug0ec32170F66045afB17f2747b36724e2,
  SecondaryMarketingHeader686cd6409234470aBb64A5fd4ced2cf2,
  AboutHero714fcbd3E14542beB71c8ed0d64e21d2,
  AboutUsPage4c857ec321b3420bAd912974fb2a308d,
  WelcomeMessageRow99bb88a982424e4e9f0251a826ceddac,
  ContentSlugF2bbf7e10df44f36A90f18ff98d801b7,
  AboutHeroHeadingC8000f3283b64f1a9bf90a56e6a777f6,
  ContentSlug78639e870dfa4c299dacBcc56e8ab1c0,
  AboutMarketingCards066453d068844ca2Bbb0Cc213d11bdd7,
  PostSlug92a5090e1d5a4e3bA2aaA124690c5c33,
  ServicesB90a63cdC0c74486Bd1fEb0723b0cff4,
  Contact2907b4a443684c8fB4024dab96f7c1ea,
  Social04814c0306f9423bB8425dbb670fd760,
  DefaultFooter380cec6cC44443a1819cE83be4ba3649,
  Header5049dfbfA5cb447c8e3817258abde62d,
  Footer9fa6603b98354d9783e64986db1835f0,
  WelcomeBack7be49b4292f14b8499b60a7367b20e6f,
  Body22cc36cfEa374c68A82cBefacce33f04,
  Quote0eeea73f0a41457fB34025d950ecce3a,
  BookStay3612cc05Ad1b455f9de934919afa0418,
  ServicesPostsF94eb81dCc8a44ac854aCb654f58a20d,
  WelcomeMessageRow92085fc722154d448fd3633053b6e2e0,
  ContentSlug97257abe9767472eA3ab5a924046ef91
}

export default Components;